import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "radio-button"
    }}>{`Radio button`}</h2>
    <ComponentCode name="Radio button" component="radio-button" variation="radio-button" hasReactVersion="radiobutton--default" hasVueVersion="radiobutton--default" hasAngularVersion="?path=/story/radio--basic" codepen="MRyjOR" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "radio-button-horizontal-group-left"
    }}>{`Radio button horizontal group left`}</h2>
    <ComponentCode name="Radio button horizontal group left" component="radio-button" variation="radio-button--horizontal-left" hasReactVersion="radiobuttongroup--default" codepen="qwZaoZ" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "radio-button-vertical-group"
    }}>{`Radio button vertical group`}</h2>
    <ComponentCode name="Radio button vertical group" component="radio-button" variation="radio-button--vertical" hasReactVersion="radiobuttongroup--default" codepen="BEKLVx" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "radio-button-vertical-group-left"
    }}>{`Radio button vertical group left`}</h2>
    <ComponentCode name="Radio button vertical group left" component="radio-button" variation="radio-button--vertical-left" hasReactVersion="radiobuttongroup--default" codepen="wZGzxX" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="radio-button" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      